import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { protectedResources, loginRequest } from '../authConfig';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { useContext, useState, useEffect, useRef } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { InputAdornment, TextField, MenuItem, Select, Skeleton, Table, TableBody, TableHead, TableRow, TableCell, Grid, Stack, Typography, Paper, Autocomplete, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { AlertComponent } from '../components/ErrorModal';
import { useParams,useNavigate } from 'react-router-dom';
import { AnwesenheitsdetailItem } from '../components/AnwesenheitsdetailItem';
import { getDateHyphenFormatSelector, getDateHyphenFormat, convertDatetoDate8am, convertDatetoDate12am, minutesDifference, convertDateTimePickerToISO, getDateTimeHyphenFormat, getTimeFromDate } from '../utils/DateUtils';
import 'dayjs/locale/de';

import '../styles/ZeiteintragPage.module.css'
import dayjs from 'dayjs';
import { UserContext } from '../components/ContextProvider';
import { useDisable } from '../hooks/useDisable';
import moment from 'moment';

function getDateAt3AM(dateString) {
    // Parse the date string to a Day.js object
    const date = dayjs(dateString);

    // Set the time to 3 AM
    const dateAt3AM = date.hour(3).minute(0).second(0).millisecond(0);

    return dateAt3AM; // Return the Day.js object with the new time
}


export const NeuerZeiteintrag = () => {
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const [states, changeStates] = useDisable({"abÜbernehmen": false, "EnNV": false, "EnN": false, "EnS": false, "cancel": false})
    const [statesLoading, changeStatesLoading] = useDisable({"abÜbernehmen": false, "EnNV": false, "EnN": false, "EnS": false, "cancel": false})

    const currentDate = getDateHyphenFormat()
    const navigate = useNavigate()

    const { id } = useParams()
    if (id) {
        console.log(id)
    } else {
        console.log("Creating new Zeiteintrag, no ID was given...")
    }
    
    const requestTaetigkeit = "catbase_jobtypes?$filter=statecode eq 0"
    const requestKunde = "accounts?$filter=statecode eq 0&$select=name,address1_city&$orderby=name"
    const requestprojekt = "catpm_projects?$filter=statuscode eq 111810000 or statuscode eq 1&$select=catpm_name,_catpm_customerid_value&$orderby=catpm_name"
    const requestArbeitspaket = "catpm_projectitems?$filter=statuscode eq 1 or statuscode eq 111810000 or statuscode eq 111810001&$select=nms_projektkosten,_catpm_projectid_value,catpm_name,catpm_budgethourstotal,catpm_budgethoursremain,catpm_budgetdaysusage,catpm_isbillable&$orderby=catpm_name"

    const getOptionKey = (option) => {
        return String(option.value)
    };

    const handleArbeitszeituebernehmen = () => {
        changeStates("all", true)
        changeStatesLoading("abÜbernehmen", true)
        let SelectedDate = getDateHyphenFormat(convertDatetoDate8am(convertDateTimePickerToISO(StartValue)))
        let requestArbeitszeit = "xrm1_attendance_details?$filter=" + "contains(xrm1_name,'" + SelectedDate + "')" + " and (xrm1_type ne 693100001 and xrm1_type ne 693100002)" + "&$select=xrm1_type,xrm1_start_date&$orderby=xrm1_start_date"
        execute("GET", protectedResources.Dynamics365.endpoint + requestArbeitszeit).then((response) => {
            let Arbeitszeittemp = response["value"].map(item => {

                // Create a new Date object from the date string
                const date = new Date(item.xrm1_start_date).toISOString()

                return date;
            })
            if (Arbeitszeittemp[0] === undefined) {
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Keine Anwesenheit an dem gegebenen Datum"} status={"error"}></AlertComponent>)   
            } else if (Arbeitszeittemp[1] !== undefined) {
                handleChangeAll(Arbeitszeittemp[0], Arbeitszeittemp[1]);
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Arbeitszeit hinzugefügt"} status={"success"}></AlertComponent>)
            } else if (Arbeitszeittemp[1] === undefined) {
                setEndValue(new Date());
                handleChangeStart(Arbeitszeittemp[0]);
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Arbeitsbeginn hinzugefügt"} status={"success"}></AlertComponent>)
            }
        }).then(() => {changeStates("all", false); changeStatesLoading("abÜbernehmen", false)});
        
    }

    const handleFakturaDropdownChange = (event) => {
        setFaktuaDropdown(event.target.value)
    }
    
    const [Taetigkeiten, setTaetigkeiten] = useState()
    const [Kunden, setKunden] = useState()
    const [Projekte, setProjekte] = useState()
    const [Projektefiltered, setProjektefiltered] = useState()
    const [Arbeitspakete, setArbeitspakete] = useState(false)
    const [Anwesenheiten, setAnwesenheiten] = useState("")
    const [ArbeitspaketeFiltered, setArbeitspaketeFiltered] = useState(false)
    const [ArbeitspaketSelected, setArbeitspaketSelected] = useState("")
    const [StundenLohn, setStundenLohn] = useState("")
    const catbase_description = useRef(null)
    const nms_remarkintern = useRef(null)

    const [ArbeitspaketeBudgetG, setArbeitspaketeBudgetG] = useState("")
    const [ArbeitspaketeBudgetR, setArbeitspaketeBudgetR] = useState("")
    const [ArbeitspaketeBudgetP, setArbeitspaketeBudgetP] = useState("")
    const [Exists, setExists] = useState(false)

    const [FakturaDropdown, setFaktuaDropdown] = useState(true)

    const [StartValue, setStartValue] = useState(new Date())
    const [EndValue, setEndValue] = useState(new Date())

    const [AlertComponentVAR, setAlertComponentVAR] = useState(false)

    const [Dauer, setDauer] = useState('0 Stunden')
    const [DauerF, setDauerF] = useState('0 Stunden')

    if (Taetigkeiten) {
        var TaetigkeitSelected = Taetigkeiten[0].value
    } else {
        var TaetigkeitSelected = ""
    }
    const [PausenZeitSelected, setPausenZeitSelected] = useState(0)
    const [KundeSelected, setKundeSelected] = useState("")
    const [ProjektSelected, setProjektSelected] = useState("")
    const [TimeItems, setTimeItems] = useState(null)

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const authRequest = {
        ...loginRequest,
    };
    
    useEffect(() => {
        if (!Taetigkeiten) {
            execute("GET", protectedResources.Dynamics365.endpoint + requestTaetigkeit).then((response) => {
                var taetigkeitentemp = response["value"].map(item => {
                    return ({"label": item.catbase_name, "value": item.catbase_jobtypeid})
                })
                setTaetigkeiten(taetigkeitentemp)
            });
        }
        if (!Kunden && Taetigkeiten) {
            execute("GET", protectedResources.Dynamics365.endpoint + requestKunde).then((response) => {
                var kundenTemp = response["value"].map(item => {
                    if (item.name !== "" && item.name){
                        return ({"label": item.name, "value": item.accountid, "loc": item.address1_city})
                    } else {
                        return ({"label": "undefined", "value": item.accountid})
                    }
                })
                setKunden(kundenTemp)
            });
        }
        if (!Projekte && Taetigkeiten && Kunden) {
            execute("GET", protectedResources.Dynamics365.endpoint + requestprojekt).then((response) => {
                var ProjekteTemp = response["value"].map(item => {
                    if (item.catpm_name !== "" && item.catpm_name){
                        return ({"label": item.catpm_name, "value": item.catpm_projectid, "parent": item._catpm_customerid_value})
                    } else {
                        return ({"label": "undefined", "value": "undefined", "parent": "undefined"})
                    }
                })
                setProjekte(ProjekteTemp)
            });
        }
        if (!Arbeitspakete && Projekte && Taetigkeiten && Kunden) {
            execute("GET", protectedResources.Dynamics365.endpoint + requestArbeitspaket).then((response) => {
                var ArbeitspaketeTemp = response["value"].map(item => {
                    if (item.catpm_name !== "" && item.catpm_name){
                        return ({"label": item.catpm_name, "value": item.catpm_projectitemid, "parent": item._catpm_projectid_value, "BudgetFull": item.catpm_budgethourstotal, "Budgedremain": item.catpm_budgethoursremain, "Budgedremainpercent": item.catpm_budgetdaysusage, "Billable": item.catpm_isbillable, "stundenlohn": item.nms_projektkosten})
                    } else {
                        return ({"label": "undefined", "value": "undefined"})
                    }
                })
                setArbeitspakete(ArbeitspaketeTemp)
            });
        }
        if (!Anwesenheiten && Arbeitspakete && Projekte && Taetigkeiten && Kunden) {
            const requestAnwesenheitBase = "xrm1_attendance_details?$filter=" + "contains(xrm1_name,'" + currentDate + "') and _xrm1_attendance_detail_employee_id_value eq " + MitarbeiterGUID

            execute("GET", protectedResources.Dynamics365.endpoint + requestAnwesenheitBase).then((response) => {
                const sortedData = response["value"].sort((a, b) => {
                    // Extracting the xrm1_name attribute from both objects
                    const nameA = a.xrm1_name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.xrm1_name.toUpperCase(); // ignore upper and lowercase
                
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                
                    // names must be equal
                    return 0;
                });
                let AnwesenheitenTemp = sortedData.map(eintrag => (
                    <AnwesenheitsdetailItem
                    name={eintrag.xrm1_name} 
                    key={eintrag.xrm1_attendance_detailid} 
                    id={eintrag.xrm1_attendance_detailid} 
                    startTime={eintrag.xrm1_start_date}
                    PausenZeit={{}}
                    WorkHours={{condition: "none"}}
                />
                ))
                setAnwesenheiten(AnwesenheitenTemp)
            });

        }
        if (!TimeItems && Anwesenheiten && Arbeitspakete && Projekte && Taetigkeiten && Kunden) {
            let nextDay = new Date(StartValue); // Create a Date object from StartValue
            nextDay.setDate(nextDay.getDate() + 1); // Add 1 day
            let requestZT = "catbase_timeitems?$filter=" + "catbase_date ge " + convertDateTimePickerToISO(StartValue).split("T")[0] + " and catbase_date lt " + convertDateTimePickerToISO(nextDay).split("T")[0] + " and _owninguser_value eq " + SystemuserGUID + "&$orderby=catbase_starttime desc&$expand=catbase_customerid"
            execute("GET", protectedResources.Dynamics365.endpoint + requestZT).then((response) => {
                if (response["value"]) {
                    setTimeItems(response["value"].map(entry => {
                        return (
                            <tr key={entry.catbase_timeitemid} className="TimeItem">
                                <td>
                                {getTimeFromDate(entry.catbase_starttime)}
                                </td>
                                <td>
                                {getTimeFromDate(entry.catbase_endtime)}
                                </td>
                                <td>
                                {entry.catbase_description}
                                </td>
                                <td>
                                {entry.catbase_customerid.name}
                                </td>
                            </tr>
                        )
                    }));
                }
                console.log()
            });
        }
        if (id && Anwesenheiten && Arbeitspakete && Projekte && Taetigkeiten && Kunden){
            console.log("getting data")
            let requestZeiteintrag = "catbase_timeitems(" + id + ")"
            execute("GET", protectedResources.Dynamics365.endpoint + requestZeiteintrag).then((response) => {
                if (response) {
                    setExists(true)
                    const responseData = response
                    TaetigkeitSelected = responseData._catbase_jobtypeid_value
                    handleChangeKunde(undefined, Kunden.filter(item => {return item.value == responseData._catbase_customerid_value})[0])
                    handleChangeProjekt(undefined, Projekte.filter(item => {return item.value == responseData._catpm_projectid_value})[0])
                    handleChangeArbeitspaket(undefined, Arbeitspakete.filter(item => {return item.value == responseData._catpm_projectitemid_value})[0])
                    catbase_description.current.value = responseData.catbase_description
                    nms_remarkintern.current.value = responseData.nms_remarkintern
                    handleChangeAll(responseData.catbase_starttime, responseData.catbase_endtime)
                    setPausenZeitSelected(responseData.catbase_breakduration)
                    setFaktuaDropdown(responseData.catbase_isbillable)
                    setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Bestehender Zeiteintrag geladen"} status={"success"}></AlertComponent>)
                }
            });
        }
    }, [execute, Taetigkeiten, Kunden, Projekte, Arbeitspakete, Anwesenheiten])
    
    const handleEarlyClickEvent = () => {
        setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Bitte warte bis der Inhalt geladen ist"} status={"error"}></AlertComponent>)
        
    }

    const handleCreateAndNew = () => {
        changeStates("all", true)
        changeStatesLoading("EnN", true)

        //code to check if fields are set
        if (!TaetigkeitSelected || !KundeSelected || !ProjektSelected || !ArbeitspaketSelected || !catbase_description.current.value) {
            setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag konnte nicht erstellt werden"} status={"error"}></AlertComponent>)
            changeStates("all", false)
            changeStatesLoading("EnN", false)
            return
        }
        const payloadData = {
            "catbase_jobtypeid@odata.bind": "/catbase_jobtypes(" + TaetigkeitSelected + ")",
            "catbase_customerid@odata.bind": "/accounts(" + KundeSelected + ")",
            "catpm_projectid@odata.bind": "/catpm_projects(" + ProjektSelected + ")",
            "catpm_projectitemid@odata.bind": "/catpm_projectitems(" + ArbeitspaketSelected + ")",
            "catbase_description": catbase_description.current.value,
            "nms_remarkintern": nms_remarkintern.current.value,
            "catbase_starttime": convertDateTimePickerToISO(StartValue),
            "catbase_endtime": convertDateTimePickerToISO(EndValue),
            "catbase_breakduration": PausenZeitSelected,
            "catbase_date": getDateAt3AM(StartValue).toISOString(),
            "catbase_durationminutes": Dauer.split(" ")[0],
            "catbase_billabledurationminutes": DauerF.split(" ")[0],
            "catbase_isbillable": FakturaDropdown
        }
        setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag wird erstellt..."} status={"info"}></AlertComponent>)
    
        execute("POST", protectedResources.Dynamics365.endpoint + "catbase_timeitems", payloadData).then((response) => {
            if (response.ok) {
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag wurde erfolgreich erstellt"} status={"success"}></AlertComponent>)

                // Reset all Form Fields
                setKundeSelected(null)
                setProjektSelected(null)
                setArbeitspaketSelected(null)
                catbase_description.current.value = ""
                nms_remarkintern.current.value = ""
                setPausenZeitSelected(0)
            } else {
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag konnte nicht erstellt werden"} status={"error"}></AlertComponent>)
            }
        }).then(() => {changeStates("all", false); changeStatesLoading("EnN", false)});;
    }

    const handleCreateNewVorlage = () => {
        changeStates("all", true)
        changeStatesLoading("EnNV", true)

        //code to check if fields are set
        if (!TaetigkeitSelected || !KundeSelected || !ProjektSelected || !ArbeitspaketSelected || !catbase_description.current.value) {
            setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag konnte nicht erstellt werden"} status={"error"}></AlertComponent>)
            changeStates("all", false)
            changeStatesLoading("EnNV", false)
            return
        }
        const payloadData = {
            "catbase_jobtypeid@odata.bind": "/catbase_jobtypes(" + TaetigkeitSelected + ")",
            "catbase_customerid@odata.bind": "/accounts(" + KundeSelected + ")",
            "catpm_projectid@odata.bind": "/catpm_projects(" + ProjektSelected + ")",
            "catpm_projectitemid@odata.bind": "/catpm_projectitems(" + ArbeitspaketSelected + ")",
            "catbase_description": catbase_description.current.value,
            "nms_remarkintern": nms_remarkintern.current.value,
            "catbase_starttime": convertDateTimePickerToISO(StartValue),
            "catbase_endtime": convertDateTimePickerToISO(EndValue),
            "catbase_breakduration": PausenZeitSelected,
            "catbase_date": getDateAt3AM(StartValue).toISOString(),
            "catbase_durationminutes": Dauer.split(" ")[0],
            "catbase_billabledurationminutes": DauerF.split(" ")[0],
            "catbase_isbillable": FakturaDropdown
        }
        setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag wird erstellt..."} status={"info"}></AlertComponent>)
    
        execute("POST", protectedResources.Dynamics365.endpoint + "catbase_timeitems", payloadData).then((response) => {
            if (response.ok) {
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag wurde erfolgreich erstellt"} status={"success"}></AlertComponent>)
                catbase_description.current.value = ""
                nms_remarkintern.current.value = ""
                setPausenZeitSelected(0)
                
            } else {
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag konnte nicht erstellt werden"} status={"error"}></AlertComponent>)
            }
        }).then(() => {changeStates("all", false); changeStatesLoading("EnNV", false)});;
    }

    const handleChangeTaetigkeit = (event, value) => {
        TaetigkeitSelected = value.value
    }

    const closeCallback = () => {
        setAlertComponentVAR(null)
    }

    const handleChangeKunde = (event, value) => {
        if (value) {
            const updatedProjekte = Projekte.filter(item => {
                return item.parent == value.value;
            });
            setKundeSelected(value.value)
            setProjektefiltered(updatedProjekte)
        } else {
            setKundeSelected("")
            setProjektefiltered(null)
        }
    }

    const handleChangeProjekt = (event, value) => {
        if (value) {
            const updatedArbeitspakete = Arbeitspakete.filter(item => {
                return item.parent == value.value;
            });
            setProjektSelected(value.value)
            setArbeitspaketeFiltered(updatedArbeitspakete)
        } else {
            setProjektSelected("")
            setArbeitspaketeFiltered(null)
        }
    }

    const handleNewAndExit = () => {
        changeStates("all", true)
        changeStatesLoading("EnS", true)
        //code to check if fields are set
        if (!TaetigkeitSelected || !KundeSelected || !ProjektSelected || !ArbeitspaketSelected || !catbase_description.current.value) {
            setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag konnte nicht erstellt werden"} status={"error"}></AlertComponent>)
            changeStates("all", false)
            changeStatesLoading("EnS", false)
            return
        }
        const payloadData = {
            "catbase_jobtypeid@odata.bind": "/catbase_jobtypes(" + TaetigkeitSelected + ")",
            "catbase_customerid@odata.bind": "/accounts(" + KundeSelected + ")",
            "catpm_projectid@odata.bind": "/catpm_projects(" + ProjektSelected + ")",
            "catpm_projectitemid@odata.bind": "/catpm_projectitems(" + ArbeitspaketSelected + ")",
            "catbase_description": catbase_description.current.value,
            "nms_remarkintern": nms_remarkintern.current.value,
            "catbase_starttime": convertDateTimePickerToISO(StartValue),
            "catbase_endtime": convertDateTimePickerToISO(EndValue),
            "catbase_breakduration": PausenZeitSelected,
            "catbase_date": getDateAt3AM(StartValue).toISOString(),
            "catbase_durationminutes": Dauer.split(" ")[0],
            "catbase_billabledurationminutes": DauerF.split(" ")[0],
            "catbase_isbillable": FakturaDropdown
        }

        setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag wird erstellt..."} status={"info"}></AlertComponent>)

        execute("POST", protectedResources.Dynamics365.endpoint + "catbase_timeitems", payloadData).then((response) => {
            if (response.ok) {
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag wurde erfolgreich erstellt"} status={"success"}></AlertComponent>)

                // Reset all Form Fields
                setKundeSelected(null)
                setProjektSelected(null)
                setArbeitspaketSelected(null)
                catbase_description.current.value = ""
                nms_remarkintern.current.value = ""
                setPausenZeitSelected(0)

                navigate(-1)
            } else {
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag konnte nicht erstellt werden"} status={"error"}></AlertComponent>)
                
            }
        }).then(() => {changeStates("all", false); changeStatesLoading("EnS", false)});
    }

    const handleSave = () => {
        setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag wird erstellt..."} status={"info"}></AlertComponent>)

        const payloadData = {
            "catbase_jobtypeid@odata.bind": "/catbase_jobtypes(" + TaetigkeitSelected + ")",
            "catbase_customerid@odata.bind": "/accounts(" + KundeSelected + ")",
            "catpm_projectid@odata.bind": "/catpm_projects(" + ProjektSelected + ")",
            "catpm_projectitemid@odata.bind": "/catpm_projectitems(" + ArbeitspaketSelected + ")",
            "catbase_description": catbase_description.current.value,
            "nms_remarkintern": nms_remarkintern.current.value,
            "catbase_starttime": convertDateTimePickerToISO(StartValue),
            "catbase_endtime": convertDateTimePickerToISO(EndValue),
            "catbase_breakduration": PausenZeitSelected,
            "catbase_date": convertDatetoDate12am(StartValue),
            "catbase_durationminutes": Dauer.split(" ")[0],
            "catbase_billabledurationminutes": DauerF.split(" ")[0],
            "catbase_isbillable": FakturaDropdown
        }
    
        execute("POST", protectedResources.Dynamics365.endpoint + "catbase_timeitems", payloadData).then((response) => {
            if (response.ok) {
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag wurde erfolgreich erstellt"} status={"success"}></AlertComponent>)
                execute("GET", protectedResources.Dynamics365.endpoint + "catbase_timeitems?$filter=catbase_date ge " + convertDatetoDate12am(new Date()) + "&$orderby=catbase_starttime desc").then((response) => {
                    if (response) {
                        setExists(true)
                        const responseData = response["value"][0]
                        TaetigkeitSelected = responseData._catbase_jobtypeid_value
                        handleChangeKunde(undefined, Kunden.filter(item => {return item.value == responseData._catbase_customerid_value})[0])
                        handleChangeProjekt(undefined, Projekte.filter(item => {return item.value == responseData._catpm_projectid_value})[0])
                        handleChangeArbeitspaket(undefined, Arbeitspakete.filter(item => {return item.value == responseData._catpm_projectitemid_value})[0])
                        catbase_description.current.value = responseData.catbase_description
                        nms_remarkintern.current.value = responseData.nms_remarkintern
                        handleChangeAll(responseData.catbase_starttime, responseData.catbase_endtime)
                        setPausenZeitSelected(responseData.catbase_breakduration)
                        setFaktuaDropdown(responseData.catbase_isbillable)
                    }
                })
            } else {
                setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Zeiteintrag konnte nicht erstellt werden"} status={"error"}></AlertComponent>)
            }
        });
    }

    const handleChangeArbeitspaket = (event, value) => {
        if (value) {
            setStundenLohn(value.stundenlohn)
            setArbeitspaketSelected(value.value)
            setArbeitspaketeBudgetG(value.BudgetFull)
            setArbeitspaketeBudgetR(value.Budgedremain)
            setArbeitspaketeBudgetP(value.Budgedremainpercent)
            setFaktuaDropdown(value.Billable)
        } else {
            setStundenLohn("")
            setArbeitspaketSelected("")
            setArbeitspaketeBudgetG("")
            setArbeitspaketeBudgetR("")
            setArbeitspaketeBudgetP("")
            setFaktuaDropdown(true)
        }
    }

    const handlePausenZeitChange = (event) => {
        setPausenZeitSelected(event.target.value)
        if (StartValue && EndValue) {
            let StartISO = convertDateTimePickerToISO(StartValue)
            let EndISO = convertDateTimePickerToISO(EndValue)

            let temp = (minutesDifference(StartISO, EndISO) - (event.target.value)) + " Minuten"
            if (temp !== NaN) {
                setDauer(temp)
                setDauerF(temp)

            } else {
                setDauer("0 Stunden")
                setDauerF("0 Stunden")
                
            }
        }
    }

    const handleChangeAll = (newValueStart, newValueEnd) => {
        console.log("Neue werte gesetzt" + newValueStart, newValueEnd)
        setStartValue(newValueStart)
        setEndValue(newValueEnd)
        setTimeItems(null)

        if (newValueStart && newValueEnd) {
            let StartISO = newValueStart
            let EndISO = newValueEnd

            let temp = (minutesDifference(StartISO, EndISO) - (PausenZeitSelected)) + " Minuten"

            if (temp !== NaN) {
                setDauer(temp)
                setDauerF(temp)
            } else {
                setDauer("0 Stunden")
                setDauerF("0 Stunden")
            }
        }
    }

    const handleChangeStart = (newValue) => {
        if(getDateHyphenFormat() != getDateHyphenFormatSelector(newValue)) {
            setTimeItems(null)
            const requestAnwesenheit = "xrm1_attendance_details?$filter=" + "contains(xrm1_name,'" + getDateHyphenFormat(convertDatetoDate8am(convertDateTimePickerToISO(newValue))) + "') and _xrm1_attendance_detail_employee_id_value eq " + MitarbeiterGUID

           execute("GET", protectedResources.Dynamics365.endpoint + requestAnwesenheit).then((response) => {
                const sortedData = response["value"].sort((a, b) => {
                    // Extracting the xrm1_name attribute from both objects
                    const nameA = a.xrm1_name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.xrm1_name.toUpperCase(); // ignore upper and lowercase
                
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                
                    // names must be equal
                    return 0;
                });
                var AnwesenheitenTemp = sortedData.map(eintrag => (
                    <AnwesenheitsdetailItem
                    name={eintrag.xrm1_name} 
                    key={eintrag.xrm1_attendance_detailid} 
                    id={eintrag.xrm1_attendance_detailid} 
                    startTime={eintrag.xrm1_start_date}
                    PausenZeit={{}}
                    WorkHours={{condition: "none"}}
                />
                ))
                setAnwesenheiten(AnwesenheitenTemp)
            });
        } 
        setStartValue(newValue)
        setEndValue(newValue)

        // Calculate Time
        if (newValue) {
            let StartISO = convertDateTimePickerToISO(newValue)
            let EndISO = convertDateTimePickerToISO(newValue)

            console.log("Start changed with values: ", StartISO, EndISO)

            let temp = (minutesDifference(StartISO, EndISO) - (PausenZeitSelected)) + " Minuten"
            if (temp !== NaN) {
                setDauer(temp)
                setDauerF(temp)

            } else {
                setDauer("0 Stunden")
                setDauerF("0 Stunden")
                
            }
        }
    }

    const handleChangeEnde = (newValue) => {
        setEndValue(newValue)

        if (StartValue && newValue) {
            let StartISO = convertDateTimePickerToISO(StartValue)
            let EndISO = convertDateTimePickerToISO(newValue)

            let temp = (minutesDifference(StartISO, EndISO) - (PausenZeitSelected)) + " Minuten"
            if (temp !== NaN) {
                setDauer(temp)
                setDauerF(temp)

            } else {
                setDauer("0 Stunden")
                setDauerF("0 Stunden")
                
            }
        }
    }

    // states: {disabled: {"abÜbernehmen": false, "EnNV": false, "EnN": false, "EnS": false}, loading: {"abÜbernehmen": false, "EnNV": false, "EnN": false, "EnS": false}}
    if (Anwesenheiten) {
        return (
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <Grid container alignItems="flex-end" justifyContent={"flex-end"} spacing={2} paddingTop={1}>
                        <Grid item xs={6} md={2} sm={6}>
                            <LoadingButton  disabled={states.cancel} variant='contained' onClick={() => {navigate(-1)}}>Schließen</LoadingButton>
                        </Grid>
                        <Grid item xs={6} md={2} sm={6} >
                            <LoadingButton loading={statesLoading.abÜbernehmen} disabled={states.abÜbernehmen} variant='contained' onClick={handleArbeitszeituebernehmen}>Arbeitszeit übernehmen</LoadingButton>
                        </Grid>
                        <Grid item xs={6} md={2} sm={6}>
                            <LoadingButton loading={statesLoading.EnNV}  disabled={states.EnNV} variant='contained' onClick={handleCreateNewVorlage}>Erstellen und neu (vorlage)</LoadingButton>
                        </Grid>
                        <Grid item xs={6} md={2} sm={6}>
                            <LoadingButton loading={statesLoading.EnN}  disabled={states.EnN} variant='contained' onClick={handleCreateAndNew}>Erstellen und neu</LoadingButton>
                        </Grid>
                        <Grid item xs={6} md={2} sm={6}>
                            <LoadingButton loading={statesLoading.EnS}  disabled={states.EnS} variant='contained' onClick={handleNewAndExit}>Erstellen und schließen</LoadingButton>
                        </Grid>
                    </Grid>

                <div id="WrapperFormMain">
                    <Grid alignItems="baseline" rowSpacing={0} sx={{marginLeft: "7.5px",marginRight: "22.5px"}} container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} sx={{height: "fit-content", paddingLeft: "7.5px"}}>
                            <Paper elevation={3} id="ZeiteintragPaper">
                                <Typography variant="h5">Zeiteintrag</Typography>
                                <div id="ZeiteintragTableWrapper">
                                    <Table id="ZeiteintragTable">
                                        <TableHead>
                                            <TableCell id="label" className="label"></TableCell>
                                            <TableCell id="value" className="label"></TableCell>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow className='ZeiteintragTR'>
                                                <TableCell className="label">Tätigkeit *</TableCell>
                                                <TableCell padding='none' className="value">
                                                    <Autocomplete className='Autocomplete' fullWidth disablePortal id="TaetigkeitSelector" defaultValue={Taetigkeiten[0]} onChange={handleChangeTaetigkeit} options={Taetigkeiten} renderInput={(params) => <TextField className='AutocompleteInput' variant='standard' {...params}/>}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='ZeiteintragTR'>
                                                <TableCell className="label">Kunde *</TableCell>
                                                <TableCell padding='none' className="value">
                                                <Autocomplete
                                                    fullWidth
                                                    disablePortal
                                                    id="KundeSelector"
                                                    getOptionLabel={(option) => option.label} // Keep label for value selection
                                                    getOptionKey={getOptionKey}
                                                    onChange={handleChangeKunde}
                                                    options={Kunden}
                                                    value={KundeSelected ? Kunden.find(item => item.value === KundeSelected) : null}
                                                    renderOption={(props, option) => (
                                                        <li {...props}>
                                                        <div>
                                                            <strong>{option.label}</strong>
                                                            <br />
                                                            <small style={{ color: "#757575" }}>{option.loc}</small>
                                                        </div>
                                                        </li>
                                                    )}
                                                    renderInput={(params) => <TextField variant="standard" {...params} />}
                                                />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='ZeiteintragTR'>
                                                <TableCell className="label">Projekt *</TableCell>
                                                <TableCell padding='none' className="value">
                                                    <Autocomplete fullWidth disablePortal id="ProjektSelector" disabled={KundeSelected === ""} onChange={handleChangeProjekt} getOptionKey={getOptionKey} options={Projektefiltered} value={ProjektSelected ? Projekte.filter(item => {return item.value == ProjektSelected})[0]: null} renderInput={(params) => <TextField variant='standard'{...params}/>}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='ZeiteintragTR'>
                                                <TableCell className="label">Arbeitspaket *</TableCell>
                                                <TableCell padding='none' className="value">
                                                    <Autocomplete fullWidth disablePortal id="ArbeitspaketSelector" disabled={ProjektSelected === ""} onChange={handleChangeArbeitspaket} getOptionKey={getOptionKey} options={ArbeitspaketeFiltered} value={ArbeitspaketSelected ? Arbeitspakete.filter(item => {return item.value == ArbeitspaketSelected})[0]: null} renderInput={(params) => <TextField variant='standard'{...params}/>}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='ZeiteintragTR'>
                                                <TableCell className="label">Beschreibung *</TableCell>
            
                                                <TableCell padding='none' className="value">
                                                    <TextField id="catbase_description" inputRef={catbase_description} multiline fullWidth rows={3} defaultValue="" variant="standard"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='ZeiteintragTR'>
                                                <TableCell className="label">Beschreibung Intern</TableCell>
            
                                                <TableCell padding='none' className="value">
                                                    <TextField id="nms_remarkintern" inputRef={nms_remarkintern} multiline fullWidth rows={3} defaultValue="" variant="standard"/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Stack spacing={2}>
                                <Paper elevation={3} id="ZeitdetailsPaper">
                                    <Typography variant="h5">Zeitdetails</Typography>
                                    <div id="ZeitdetailsTableWrapper">
                                        <Table id="ZeitdetailsTable">
                                            <TableHead>
                                                <TableCell id="label" className="label"></TableCell>
                                                <TableCell id="value" className="label"></TableCell>
                                            </TableHead>
                                            <TableRow className='ZeiteintragTR'>
                                                <TableCell className="label">Start</TableCell>
                                                
                                                <TableCell padding='none' className="value">
                                                    <DateTimePicker id="StartValue" value={dayjs(StartValue)} onChange={handleChangeStart} ></DateTimePicker>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='ZeiteintragTR'>
                                                <TableCell className="label">Ende</TableCell>
                                                
                                                <TableCell padding='none' className="value">
                                                    <DateTimePicker id="EndValue" value={dayjs(EndValue)} onChange={handleChangeEnde} ></DateTimePicker>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='ZeiteintragTR'>
                                                <TableCell className="label">Pause</TableCell>
                                                
                                                <TableCell padding='none' className="value">
                                                <Select style={{height: "53px"}} variant='standard' fullWidth onChange={handlePausenZeitChange} value={PausenZeitSelected}>
                                                    <MenuItem value={0}>0 Minuten</MenuItem>  
                                                    <MenuItem value={10}>10 Minuten</MenuItem>
                                                    <MenuItem value={15}>15 Minuten</MenuItem>
                                                    <MenuItem value={30}>30 Minuten</MenuItem>
                                                    <MenuItem value={45}>45 Minuten</MenuItem>
                                                    <MenuItem value={60}>60 Minuten</MenuItem>
                                                    <MenuItem value={75}>75 Minuten</MenuItem>
                                                    <MenuItem value={90}>90 Minuten</MenuItem>
                                                </Select>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </div>
                                </Paper>
                                <Paper elevation={3} id="ZuordnungPaper">
                                    <Typography variant="h5">Anwesenheit am {getDateHyphenFormat(convertDatetoDate8am(convertDateTimePickerToISO(StartValue)))}</Typography>
                                    <div id="BudgetArbeitspaketWrapper">
                                        <table>
                                            <tbody>
                                                {Anwesenheiten}
                                            </tbody>
                                        </table>
                                    </div>
                                </Paper>
                                <Paper elevation={3} id="ZuordnungPaper">
                                    <div id="ZeiteintraegeView">
                                        <Typography variant="h5">Zeiteinträge am {getDateHyphenFormat(convertDatetoDate8am(convertDateTimePickerToISO(StartValue)))}</Typography>
                                        <div id='BudgetArbeitspaketWrapper'>
                                            <table>
                                                <thead>
                                                    <tr className="TimeItem">
                                                        <td>
                                                        Start
                                                        </td>
                                                        <td>
                                                        Ende
                                                        </td>
                                                        <td>
                                                        Beschreibung
                                                        </td>
                                                        <td>
                                                        Kunde
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {TimeItems}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Paper>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Stack spacing={2}>
                                <Paper elevation={3} id="DatumUndDauerPaper">
                                    <Typography variant="h5">Datum und Dauer</Typography>
                                    <div id="DNDTableWrapper">
                                        <Table>
                                            <TableHead>
                                                <TableCell id="label" className="label"></TableCell>
                                                <TableCell id="value" className="label"></TableCell>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow className='ZeiteintragTR'>
                                                    <TableCell className="label">Datum</TableCell>
                                                    
                                                    <TableCell padding='none' className="value">
                                                        <DatePicker id="catbase_date" value={getDateAt3AM(StartValue)} onChange={(newValue) => {setStartValue(convertDatetoDate8am(newValue))}} viewRenderers={{ hours: renderTimeViewClock, minutes: renderTimeViewClock}}></DatePicker>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className='ZeiteintragTR'>
                                                    <TableCell className="label">Dauer</TableCell>
                                                    
                                                    <TableCell padding='none' className="value">
                                                        <TextField id='Text-Display' fullWidth variant='standard' margin='none' value={Dauer} InputProps={{readOnly: true, startAdornment: <InputAdornment position="start"><LockOutlinedIcon /></InputAdornment>,}}></TextField>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className='ZeiteintragTR'>
                                                    <TableCell className="label">Dauer Faktura</TableCell>
                                                    
                                                    <TableCell padding='none' className="value">
                                                        <TextField id='Text-Display' fullWidth variant='standard' margin='none' value={DauerF} InputProps={{readOnly: true, startAdornment: <InputAdornment position="start"><LockOutlinedIcon /></InputAdornment>,}}></TextField>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className='ZeiteintragTR'>
                                                    <TableCell className="label">Fakturierbar</TableCell>
                                                    
                                                    <TableCell padding='none' className="value">
                                                        <Select style={{height: "53px"}} variant='standard' fullWidth value={FakturaDropdown} onChange={handleFakturaDropdownChange}>
                                                            <MenuItem value={false}>Nein</MenuItem>  
                                                            <MenuItem value={true}>Laut Einstellung</MenuItem>
                                                        </Select>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Paper>
                                <Paper elevation={3} id="BudgetArbeitspaketPaper">
                                    <Typography variant="h5">Budget Arbeitspaket</Typography>
                                    <div id="BudgetArbeitspaketWrapper">
                                        <Table>
                                            <TableHead>
                                                <TableCell id="label" className="label"></TableCell>
                                                <TableCell id="value" className="label"></TableCell>
                                            </TableHead>
                                            <TableBody>                                            
                                                <TableRow className='ZeiteintragTR'>
                                                    <TableCell className="label">Budget gesamt (h)</TableCell>
                                                    
                                                    <TableCell padding='none' className="value">
                                                        <TextField id="Text-Display" InputProps={{readOnly: true, startAdornment: <InputAdornment position="start"><LockOutlinedIcon /></InputAdornment>,}} fullWidth value={ArbeitspaketeBudgetG} variant="standard"/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className='ZeiteintragTR'>
                                                    <TableCell className="label">Budget Rest (h)</TableCell>
                                                    
                                                    <TableCell padding='none' className="value">
                                                        <TextField id="Text-Display" InputProps={{readOnly: true, startAdornment: <InputAdornment position="start"><LockOutlinedIcon /></InputAdornment>,}} fullWidth value={ArbeitspaketeBudgetR} variant="standard"/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className='ZeiteintragTR'>
                                                    <TableCell className="label">Budget verbrauch (%)</TableCell>
                                                    
                                                    <TableCell padding='none' className="value">
                                                        <TextField id="Text-Display" InputProps={{readOnly: true, startAdornment: <InputAdornment position="start"><LockOutlinedIcon /></InputAdornment>,}} fullWidth value={ArbeitspaketeBudgetP} variant="standard"/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className='ZeiteintragTR'>
                                                    <TableCell className="label">Stundenlohn</TableCell>
                                                    
                                                    <TableCell padding='none' className="value">
                                                        <TextField id="Text-Display" InputProps={{readOnly: true, startAdornment: <InputAdornment position="start"><LockOutlinedIcon /></InputAdornment>,}} fullWidth value={StundenLohn} variant="standard"/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Paper>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
                {AlertComponentVAR}
                </LocalizationProvider>
            </MsalAuthenticationTemplate>
        );
    } else {
        return (
            <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            >                    
                <Grid container alignItems="flex-end" justifyContent={"flex-end"} spacing={2} paddingTop={1}>
                    <Grid item xs={6} md={2} sm={6} >
                        <LoadingButton variant='contained' onClick={handleEarlyClickEvent}>Arbeitszeit übernehmen</LoadingButton>
                    </Grid>
                    <Grid item xs={6} md={2} sm={6}>
                        <LoadingButton variant='contained' onClick={handleEarlyClickEvent}>Erstellen und neu (vorlage)</LoadingButton>
                    </Grid>
                    <Grid item xs={6} md={2} sm={6}>
                        <LoadingButton variant='contained' onClick={handleEarlyClickEvent}>Erstellen und neu</LoadingButton>
                    </Grid>
                    <Grid item xs={6} md={2} sm={6}>
                        <LoadingButton variant='contained' onClick={handleEarlyClickEvent}>Erstellen und schließen</LoadingButton>
                    </Grid>
                </Grid>
                <Grid alignItems="baseline" rowSpacing={0} sx={{marginLeft: "15px", marginRight: "15px"}} container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} sx={{height: "fit-content"}}t->
                        <Skeleton></Skeleton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Stack spacing={2}>
                            <Skeleton></Skeleton>
                            <Skeleton></Skeleton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Stack spacing={2}>
                            <Skeleton></Skeleton>
                            <Skeleton></Skeleton>
                        </Stack>
                    </Grid>
                </Grid>
            </MsalAuthenticationTemplate>
        )
    }
};
